<template>
    <b-overlay :show="is_busy" rounded="sm">
        <b-container fluid>
            <div>
                <h2 class="text-center"><strong>Pricing Plan</strong></h2>
                <p v-if="feature_id" class="alert alert-danger text-center p-2 text-danger">Your current plan (<b>{{ plan }} plan</b>) does not contain the feature of {{ feature.name }}, kindly upgrade to have access to the features.</p>
            </div>

            <div class="price_compare">
                <table>
                    <tbody>
                        <tr>
                            <td class="first rowfirst">
                                <div class="title">
                                    <div class="arrow_box"><!-- <h5>All Reseller Hosting Features</h5> -->
                                        <h3 class="caps">Pricing Plans</h3>
                                    </div>
                                </div>
                            </td>
                          
                            <td class="rowsremain" v-for="price in prices" :key="price.id">
                                <div class="prices">
                                    <h4 class="caps">{{ price.name }}</h4>
                                    <strong>₦{{ price.amount }}</strong> <b>Annually</b>
                                </div>
                            </td>
                        </tr>
                       
                        <tr v-for="feat in allfeatures" :key="feat.id">
                            <th class="text-center">{{ feat.name }}</th>
                            
                            <th v-for="price in prices" :key="price.id" class="text-center">
                               
                                <span class="text-info" v-if="getFeatures(price.features).includes(feat.id)" style="font-size:20px">&#10004;</span>
                                <span class="text-danger" v-else style="font-size:20px">X</span>
                            </th>            
                        </tr>
                   
                        
                        <tr class="text-center">
                            <td class="first rowfirst"></td>
                            <td class="rowsremain" v-for="price in prices" :key="price.id">
                                <a href="#" class="btn btn-sm btn-info">Upgrade</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> 

        </b-container>
    </b-overlay>
</template>

<script>
import axios from 'axios';

export default {
    components: {
    },
    data() {
        return {
            is_busy: false,
            plan: '',
            prices: '',
            feature_id: '',
            feature: '',
            allfeatures: '',
            ftselected : '',
        }
    },

    created(){
        this.plan = JSON.parse(localStorage.getItem('user')).data.school.plan.name
        this.prices = JSON.parse(localStorage.getItem('user')).data.prices
        this.loadPage();
    },

    methods: {
      loadPage(){
        // if (this.is_busy) return;
        // this.is_busy = true;
        
        this.feature_id = this.$route.params.plan_id;    
        axios.get('/getfeature/' + this.feature_id)
        .then((response) => {
            this.feature = response.data.data.feature;
            this.allfeatures = response.data.data.allfeatures;
        })
        .catch((err)=>{
            Swal.fire(
                "Error!",
                'Feature Not Found',
                "error"
            );
        })
        .finally(() => {
            this.is_busy = false;
        })
      },

      getFeatures(x){
        var selected = [];
                                   
        x.forEach(function (ft) {
            selected.push(ft.id);
        });
        return selected;
      }
    },
}
</script>

<style>
    .price_compare {
        float: left;
        width: 100%;
        padding: 90px 0px;
        text-align: center;
    }
    .price_compare table {
        width: 100%;
        font-family: 'Roboto', sans-serif;
    }
    .price_compare table,
    .price_compare th,
    .price_compare td {
        border-collapse: separate;
        border-spacing: 10px 1px;
    }
    .price_compare table i {
        font-size: 14px;
        border-radius: 100%;
        width: 23px;
        height: 23px;
        text-align: center;
        vertical-align: middle;
        line-height: 22px;
        background: #fff;
    }
    .price_compare table td.first {
        background: #fff;
        border: none;
    }
    .price_compare td {
        padding: 12px 0px 12px 0px;
        text-align: left;
        background: #f9f9f9;
        text-align: center;
        color: #2a363f;
    }
    .price_compare th {
        padding: 12px 0px 12px 0px;
        text-align: left;
        background: #f3f3f3;
        text-align: center;
        font-weight: normal;
        color: #2a363f;
    }
    .price_compare th.alileft,
    .price_compare td.alileft {
        text-align: left;
        padding: 12px 0px 12px 18px;
        color: #2a363f;
        font-weight: 500;
        font-size: 15px;
    }
    .price_compare .rowfirst {
        width: 20%;
    }
    .price_compare .rowsremain {
        width: 15%;
        background: #eee;
    }
    .price_compare .rowsremain2 {
        width: 10%;
        background: #eee;
    }
    .price_compare .title {
        float: left;
        width: 75%;
        text-align: right;
    }
    .price_compare .title .arrow_box {
        position: relative;
        background: #5f686f;
        padding: 12px 20px 12px 0px;
    }
    .price_compare .title .arrow_box:after {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(153, 153, 153, 0);
        border-left-color: #5f686f;
        border-width: 18px;
        margin-top: -18px;
    }
    .price_compare .title h5 {
        color: #fff;
        margin-bottom: 0px;
        font-weight: 300;
    }
    .price_compare .title h3 {
        color: #fff;
        font-weight: 900;
        margin-bottom: 0px;
    }
    .price_compare .prices {
        float: left;
        width: 100%;
        text-align: center;
        padding: 20px 0px 30px 0px;
    }
    .price_compare .prices h4 {
        margin-bottom: 18px;
    }
    .price_compare .prices strong {
        color: #86c724;
        font-size: 45px;
        font-weight: 800;
        display: block;
    }
    .price_compare .prices strong i {
        color: #2a363f;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        background: none;
    }
    .price_compare .prices b {
        color: #727272;
        font-size: 12px;
        font-weight: 300;
        font-style: normal;
        display: block;
        margin-bottom: 22px;
        margin-top: 5px;
    }
    .price_compare .prices b em {
        font-style: normal;
        text-decoration: line-through;
    }
    .price_compare .prices a {
        color: #fff;
        font-size: 14px;
        background: #2a363f;
        padding: 10px 27px;
        border-radius: 3px;
        text-transform: uppercase;
        font-weight: 600;
        transition: all 0.3s ease;
    }
    .price_compare .prices a:hover {
        color: #fff;
        background: #86c724;
    }
    .price_compare .rowsremain.center {
        background: #2a363f;
    }
    .price_compare .rowsremain.center .prices strong {
        color: #fff;
    }
    .price_compare .rowsremain.center .prices a {
        color: #2a363f;
        background: #fff;
    }
    .price_compare .rowsremain.center .prices a:hover {
        color: #fff;
        background: #86c724;
    }
    .price_compare .rowsremain.center .prices i {
        color: #fff;
    }
    .price_compare .rowsremain.center span {
        color: #fff;
        padding: 3px 10px 4px 10px;
        background: #86c724;
        margin-top: -62px;
        margin-bottom: 32px;
        display: block;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.5px;
    }
</style>
